import axios from 'axios'
export default function(Vue) {
  Vue.prototype.prepareOptions = function() {
    // 全域性函式1 加解密設定
    var key = '####qwertyaa82803323bbtrewqq####'
    var iv = '####qwertyaa82803323bbtrewqq####'
    return {
      key: this.CryptoJS.enc.Utf8.parse(key),
      // key: key,
      iv: this.CryptoJS.enc.Utf8.parse(iv),
      // iv: iv,
      mode: this.CryptoJS.mode.CBC,
      pad: this.CryptoJS.pad.Pkcs7
    }
  }
  Vue.prototype.EncryptAes = function(str) {
    // 全域性函式2  加密
    var options = this.prepareOptions()

    str = this.CryptoJS.AES.encrypt(str, options.key, {
      iv: options.iv,
      mode: options.mode,
      padding: options.pad
    })
    str = str.toString()
    return str
  }
  Vue.prototype.DecryptAes = function(str) {
    // 全域性函式2 解密
    var options = this.prepareOptions()

    str = this.CryptoJS.AES.decrypt(str, options.key, {
      iv: options.iv,
      mode: options.mode,
      padding: options.pad
    })
    this.plainStr = this.CryptoJS.enc.Utf8.stringify(str)
    return this.plainStr
  }
  // 加密用的 api (統一接口作處理)
  Vue.prototype.$encryptApi = axios.create({
    baseURL: process.env.VUE_APP_LOBBYAPI
  })

  // axios 攔截器，傳出前先加密 data
  Vue.prototype.$encryptApi.interceptors.request.use(
    config => {
      if (config.encode === true) {
        config.data = Vue.prototype.EncryptAes(JSON.stringify(config.data))
      }
      return config
    },
    error => {
      console.log(error) // for debug
      return Promise.reject(error)
    }
  )

  // axios 攔截器，收到先解密 data
  Vue.prototype.$encryptApi.interceptors.response.use(
    res => {
      if (res.config.encode === true) {
        res.data = JSON.parse(Vue.prototype.DecryptAes(res.data))
      }
      return res
    },
    error => {
      console.log('err' + error) // for debug
      return Promise.reject(error)
    }
  )
}
