<template>
  <div
    id="app"
    :class="[{ scrollUp: device === 'mobile' && logingStatus === true }, { bg: logingStatus === true }]"
    ref="app"
  >
    <div class="sound">
      <audio :src="require('./assets/sound/success.mp3')" ref="success"></audio>
      <audio :src="require('./assets/sound/fail.mp3')" ref="fail"></audio>
    </div>
    <div
      class="turnGif"
      v-if="device !== 'windows' && device !== 'mac' && orientation === false && openGame === false && portraitGame === false"
      @touchstart="stopMove"
    >
      <img :src="require('./assets/login/orientation.gif')" class="orientation" />
    </div>
    <!-- browser !== 'chrome' && -->
    <div
      class="scrollGif"
      ref="scrollGif"
      v-show="
        scrollUp === true &&
          device === 'mobile' &&
          logingStatus === true &&
          orientation === true &&
          lobbyOpenKeyboard === false &&
          portraitGame === false
      "
    >
      <img :src="require('./assets/login/swiper-safari.gif')" class="orientation" />
    </div>
    <div
      class="androidGif"
      v-if="
        device === 'android' &&
          lobbyOpenKeyboard === false &&
          orientation === true &&
          logingStatus === true &&
          portraitGame === false &&
          fullscreen === false
      "
      @click="androidfullscreen"
    >
      <img :src="require('./assets/login/swiper-chrome.gif')" class="orientation" />
    </div>
    <div class="main">
      <div class="Login" v-if="logingStatus === false">
        <div class="wrap">
          <div class="logo"><img :src="require('./assets/login/DOUBLE DRAGON_LOGO.png')" alt="logo" /></div>
          <div class="user">
            <div class="player">
              <div class="text">
                <img :src="require('./assets/login/player.png')" alt="player" />
              </div>
              <div class="input">
                <input
                  type="text"
                  name="player"
                  id="player"
                  autocomplete="off"
                  v-model="user.account"
                  data-name="account"
                  @focus="onInputFocus"
                  @input="onInputChange"
                />
              </div>
              <div class="remember">
                <!-- <input type="checkbox" name="remember" id="remember" v-model="rememberMe" /> -->
                <img :src="require('./assets/login/remind_box.png')" alt="rememberMe" @click="rememberMe = true" />
                <img
                  class="tick"
                  :src="require('./assets/login/tick.png')"
                  alt="rememberMe"
                  v-show="rememberMe"
                  @click="rememberMe = false"
                />
              </div>
            </div>
            <div class="password">
              <div class="text">
                <img :src="require('./assets/login/password.png')" alt="password" />
              </div>
              <div class="input">
                <input
                  type="password"
                  name="password"
                  autocomplete="off"
                  id="password"
                  v-model="user.password"
                  data-name="password"
                  @focus="onInputFocus"
                  @input="onInputChange"
                />
              </div>
              <div class="keyboard" v-if="device === 'windows'" @click="openKeyboard = !openKeyboard">
                <img :src="require('./assets/login/keyboard.png')" alt="keyboard" />
              </div>
            </div>
          </div>
          <div class="feature">
            <div class="sumbit">
              <img :src="require('./assets/login/s0_lobby_loading_ok_button_a.png')" alt="sumbit" @click="login" />
              <div class="login" @click="login">OK</div>
            </div>
            <div class="version">{{ version }}</div>
          </div>
          <div class="keyboardWrap" v-show="openKeyboard === true">
            <SimpleKeyboard @onChange="onChange" :input="user[inputName]" :inputName="inputName"></SimpleKeyboard>
          </div>
          <div class="msg" v-if="browser === 'chrome' && device === 'mobile'">
            <img :src="require('./assets/login/msg_01.png')" alt="msg" />
          </div>
        </div>
        <!-- <img src="../assets/login/s0_lobby_loading_background.png" alt=""> -->
      </div>

      <iframe
        :class="{ chrome: browser === 'chrome' }"
        id="lobbyiframe"
        v-if="openLobby === true"
        :src="lobbyUrl"
        frameborder="0"
        scrolling="no"
        ref="lobbyiframe"
      ></iframe>
      <!-- <iframe id="gameiframe" v-if="openGame === true" :src="gameUrl" frameborder="0" scrolling="no"></iframe> -->
    </div>
  </div>
</template>

<script>
import SimpleKeyboard from './components/SimpleKeyboard'
export default {
  components: {
    SimpleKeyboard
  },
  data() {
    return {
      user: {
        account: '',
        password: ''
      },
      auth: {
        user_id: '',
        token: ''
      },
      inputName: '', // 小鍵盤
      rememberMe: false,
      openKeyboard: false,
      tokenIntervalId: null,
      version: '',
      device: '', // 裝置
      browser: '', // 瀏覽器
      orientation: '',
      scrollUp: true, // 上滑
      lobbyOpenKeyboard: false, // 大廳小鍵盤
      portraitGame: false, // 直版遊戲，轉向用
      logingStatus: false,
      openLobby: false,
      url: '',
      lobbyUrl: '',
      openGame: false,
      gameUrl: '',
      fullscreen: false // 安卓用
    }
  },
  methods: {
    stopMove(e) {
      e.preventDefault() // 禁止轉向圖放大
    },
    onChange(input) {
      this.user[this.inputName] = input
    },
    onInputChange(input) {
      this.user[this.inputName] = input.target.value
    },
    onInputFocus(input) {
      this.inputName = input.target.dataset.name
    },
    login() {
      const api = '/auth'

      const sendData = {
        account: this.user.account,
        password: this.user.password
      }
      this.$encryptApi
        .post(api, sendData)
        .then(res => {
          this.$refs.success.play()
          this.user = { account: '', password: '' }
          this.auth = res.data.data
          localStorage.setItem('auth', JSON.stringify(this.auth))
          this.url = res.data.data.url
          localStorage.setItem('url', JSON.stringify(this.auth.url))
          this.lobbyUrl = `${process.env.VUE_APP_URL}${res.data.data.url}?token=${res.data.data.token}&&id=${res.data.data.user_id}`
          this.openLobby = true
          this.tokenIntervalId = setInterval(() => {
            this.retoken()
          }, 180000)
          this.logingStatus = true
          this.openKeyboard = false
          if (this.rememberMe === true) {
            localStorage.setItem('player', sendData.account)
          } else {
            localStorage.removeItem('player')
          }
        })
        .catch(err => {
          this.$refs.fail.play()
          alert(err.response.data.msg)
          if (err.response.data.code === 2) {
            this.$router.go(0)
          }
        })
    },
    getLobbyUri() {
      const api = '/lobbyuri'
      const sendData = {
        token: this.auth.token,
        user_id: this.auth.user_id
      }
      this.$encryptApi
        .post(api, sendData)
        .then(res => {
          this.lobbyUrl = `${process.env.VUE_APP_URL}${res.data.data.uri}?token=${this.auth.token}&&id=${
            this.auth.user_id
          }?ignore=${Math.floor(Math.random() * 1000)}`
          console.log(res, 456)
        })
        .catch(err => {
          this.$refs.fail.play()
          alert(err.response.data.msg)
          localStorage.removeItem('auth')
          this.$router.go(0)
        })
    },

    retoken() {
      const api = '/refreshToken'

      const sendData = this.auth
      this.$encryptApi
        .post(api, sendData)
        .then(res => {
          this.auth = res.data.data
          localStorage.setItem('auth', JSON.stringify(res.data.data))
        })
        .catch(err => {
          alert(err.response.data.msg)
        })
    },
    deviceOrientation() {
      // 轉向判斷
      switch (window.orientation) {
        case 90:
        case -90:
          this.orientation = true
          break
        case 0:
          this.orientation = false
          break
      }
    },
    androidfullscreen() {
      const docElm = document.documentElement
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen()
      } else if (docElm.mozRequestFullScreen) {
        // FireFox
        docElm.mozRequestFullScreen()
      } else if (docElm.webkitRequestFullScreen) {
        // Chrome等
        docElm.webkitRequestFullScreen()
      } else if (docElm.msRequestFullscreen) {
        // IE11
        docElm.msRequestFullscreen()
      }
      this.fullscreen = true
    }
  },
  mounted() {
    // 刷新不登出
    if (localStorage.getItem('auth') !== null) {
      this.auth = JSON.parse(localStorage.getItem('auth'))
      this.url = JSON.parse(localStorage.getItem('url'))
      this.getLobbyUri()
      this.openLobby = true
      this.logingStatus = true
      this.openGame = false
      this.tokenIntervalId = setInterval(() => {
        console.log('刷新觸發retoken')
        this.retoken()
      }, 180000)
    }
    // rememberMe 功能
    if (localStorage.getItem('player') !== null) {
      this.user.account = localStorage.getItem('player')
      this.rememberMe = true
    }

    // 版號
    this.version = process.env.VUE_APP_VERSION

    // 判斷裝置
    const detecction = window.navigator.userAgent.toLowerCase()
    if (detecction.indexOf('windows') !== -1) {
      this.device = 'windows'
    } else if (detecction.indexOf('android') !== -1) {
      this.device = 'android'
    } else if (detecction.indexOf('intel mac') !== -1) {
      this.device = 'mac'
    } else if (detecction.indexOf('iphone') !== -1) {
      this.device = 'mobile'
    }
    // 判斷瀏覽器
    if (detecction.indexOf('crios') !== -1 || detecction.indexOf('chrome') !== -1) {
      this.browser = 'chrome'
      const htmlElement = document.getElementsByTagName('html')[0]
      htmlElement.style.background = '#382114'
    }
    // 轉向
    if (this.device === 'mobile' || this.browser === 'chrome' || this.device === 'android') {
      window.addEventListener('orientationchange', this.deviceOrientation)
      if (window.orientation === 90 || window.orientation === -90) {
        this.orientation = true
      } else {
        this.orientation = false
      }
    }
    // 將滑鼠右鍵事件取消
    document.oncontextmenu = function() {
      window.event.returnValue = false
    }

    // 安卓橫向轉全螢幕
    window.onresize = () => {
      this.androidOrientation = window.screen.orientation.type
      this.screenHeight = screen.height
      this.innerHeight = window.innerHeight
    }

    // 遊戲端監控 關閉iframe
    window.addEventListener('message', e => {
      console.log(e.data, 'cocos')
      if (e.data === 'logout' || e.data === 'token') {
        this.openLobby = false
        this.logingStatus = false
        this.auth = { user_id: '', token: '' }
        clearInterval(this.tokenIntervalId)
        localStorage.removeItem('auth')
        localStorage.removeItem('url')
      } else if (e.data.state === 'playgame') {
        // this.openGame = true
        // this.gameUrl = e.data.url
        // this.lobbyUrl = `${e.data.url.slice(0, e.data.url.indexOf('token'))}token=${this.auth.token}}`
        this.lobbyUrl = `${e.data.url}?ignore=${Math.floor(Math.random() * 1000)}`

        // 判斷是否為直版遊戲
        if (e.data.url) {
          const allVerticalGame = ['Slot_FootballChampionJoy', 'Fish_Narcos', 'MiniGame_AngelDevil', 'Slot_MahJongWays']
          const isVerticalGame = allVerticalGame.find(game => e.data.url.split('/')[4] === game)
          if (isVerticalGame) {
            this.portraitGame = true
          }
        }
        // setTimeout(() => {
        //   // this.openLobby = false
        //   // this.lobbyUrl = ''
        // }, 1000)
      } else if (e.data === 'closeWindow') {
        // setTimeout(() => {
        //   // this.openGame = false
        //   // this.gameUrl = ''
        // }, 1000)
        this.portraitGame = false
        // this.openLobby = true
        this.lobbyUrl = `${process.env.VUE_APP_URL}${this.url}?token=${this.auth.token}&&id=${
          this.auth.user_id
        }?ignore=${Math.floor(Math.random() * 1000)}`
      } else if (e.data === 'openKeyboard') {
        this.lobbyOpenKeyboard = true
      } else if (e.data === 'closeKeyboard') {
        this.lobbyOpenKeyboard = false
      } else if (e.data === 'portraitGame') {
        this.portraitGame = true
      } else if (e.data === 'change') {
        this.getLobbyUri()
      }
    })

    // 判斷關閉視窗還是刷新，來清除本地資料
    let beginTime = 0 // 開始時間
    let differTime = 0 // 時間差
    window.onunload = function() {
      differTime = new Date().getTime() - beginTime
      if (differTime <= 3) {
        localStorage.removeItem('auth')
      } else {
        clearInterval(this.tokenIntervalId)
      }
    }
    window.onbeforeunload = function() {
      beginTime = new Date().getTime()
    }

    window.addEventListener('scroll', e => {
      // 讓優化上滑的10%給取消
      // if (window.scrollY < 39) {
      //   const htmlElement = document.getElementsByTagName('body')[0]
      //   htmlElement.style.transform = 'unset'
      // }
      if (window.innerWidth > window.innerHeight) {
        if (window.outerHeight === window.innerHeight) {
          // 沒有網址列
          this.scrollUp = false
        } else {
          // 有網址列
          this.scrollUp = true
        }
      }
      if (this.browser === 'chrome' && this.device !== 'android') {
        if (outerHeight - innerHeight <= 30) {
          // 沒有網址列
          this.scrollUp = false
        } else {
          this.scrollUp = true
        }
      }
    })

    // 縮小回視窗
    window.addEventListener('focus', () => {
      if (this.device === 'android') this.fullscreen = false
    })
  },
  watch: {
    innerHeight() {
      if (this.innerHeight === this.screenHeight) return
      this.fullscreen = this.androidOrientation.indexOf('landscape') < 0
    },
    // rememberMe 功能
    logingStatus(val) {
      const htmlElement = document.getElementsByTagName('html')[0]
      if (val === false) {
        this.user.account = localStorage.getItem('player') ? localStorage.getItem('player') : ''
        htmlElement.style.background = '#382114'
      } else {
        htmlElement.style.background = '#000'
      }
    },
    orientation(newVal, oldVal) {
      if (newVal === true) {
        this.$refs.scrollGif.style.top = '0'
        setTimeout(() => {
          if (window.outerHeight === window.innerHeight) {
            this.scrollUp = false
          } else {
            this.scrollUp = true
          }
        }, 100)
      } else {
        this.$refs.scrollGif.style.top = '0'
      }
    },

    lobbyUrl() {
      this.$nextTick(() => {
        const iframe = document.getElementById('lobbyiframe')
        if (iframe !== null) {
          iframe.contentWindow.close()
          iframe.src = this.lobbyUrl

          // 另一種判斷直版方法
          // iframe.onload = function () {
          //   console.log(iframe.contentWindow.isPortrait, iframe.contentWindow)
          // }
        }
      })
    },

    scrollUp(val) {
      if (val === true) {
        window.scrollTo(0, 0)
      }
    }
  },
  computed: {}
}
</script>

<style lang="scss">
@import '@/assets/helpers/all.scss';

@font-face {
  font-family: SQR;
  src: url('./assets/helpers/font/SQR721DN.TTF');
}
</style>
