<template>
  <div :class="keyboardClass"></div>
</template>

<script>
import Keyboard from 'simple-keyboard'
import 'simple-keyboard/build/css/index.css'

export default {
  name: 'SimpleKeyboard',
  props: {
    keyboardClass: {
      default: 'simple-keyboard',
      type: String
    },
    input: {
      type: String
    },
    inputName: {
      type: String
    },
    theme: String
  },
  data: () => ({
    keyboard: null
  }),
  mounted() {
    this.keyboard = new Keyboard({
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      inputName: this.inputName,
      layout: {
        default: [' 1 2 3 4 5 6 7 8 9 0 {bksp}', ' q w e r t y u i o p', '{lock} a s d f g h j k l', ' z x c v b n m '],
        shift: [' 1 2 3 4 5 6 7 8 9 0 {bksp}', ' Q W E R T Y U I O P', '{lock} A S D F G H J K L', ' Z X C V B N M ']
      },
      display: {
        '{space}': 'space',
        '{lock}': 'caps',
        '{bksp}': 'backspace'
      }
    })
  },
  methods: {
    onChange(input) {
      this.$emit('onChange', input)
    },
    onKeyPress(button) {
      this.$emit('onKeyPress', button)

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === '{shift}' || button === '{lock}') this.handleShift()
    },
    handleShift() {
      const currentLayout = this.keyboard.options.layoutName
      const shiftToggle = currentLayout === 'default' ? 'shift' : 'default'

      this.keyboard.setOptions({
        layoutName: shiftToggle
      })
    }
  },
  watch: {
    inputName(inputName) {
      // console.log("SimpleKeyboard: inputName updated", inputName);
      this.keyboard.setOptions({ inputName })
    },
    input(input) {
      // console.log(
      //   "SimpleKeyboard: input Updated",
      //   this.keyboard.options.inputName,
      //   input
      // );
      this.keyboard.setInput(input)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
